/*general site css*/

body {
  font-family: "Roboto";
  color: #919191;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

h5 {
  font-weight: 400;
  color: #919191;
}

label {
  text-align: left;
  color: #919191;
  margin-bottom: 4px;
}

.center-col {
  text-align: center;
  margin: auto;
}

.row-top-spacer {
  margin-top: 1rem;
}

div.form-group {
  text-align: left;
}

button.site {
  color: #ffffff;
  background-color: #4aace9;
  border-radius: 3px;
  border: none;
  padding: 0.25rem 2rem 0.25rem 2rem;
}

button.cancel {
  color: #ffffff;
  background-color: #808080;
  border-radius: 3px;
  border: none;
  padding: 0.25rem 2rem 0.25rem 2rem;
}

button.button-left {
  margin-right: 1rem;
}

a.small-link {
  color: #919191;
  font-size: 0.75rem;
  text-decoration: underline;
}

a.small-link-button,
a.small-link-button:visited,
a.small-link-button:active,
a.small-link-button:focus,
a.small-link-button:link {
  color: #6086ee;
  font-size: 1rem;
  background-color: transparent;
  border: none;
}

button.small-link-button,
button.small-link-button:visited,
button.small-link-button:active,
button.small-link-button:focus,
button.small-link-button:link {
  color: #6086ee;
  font-size: 1rem;
  background-color: transparent;
  border: none;
}

div.notification {
  margin-top: 1rem;
  padding: 0.8rem;
  font-size: 1.25rem;
  color: #ffffff;
  background-color: #808080;
  border-radius: 5px;
  margin-left: 1rem;
}

div.product-icon {
  text-align: center;
  border-radius: 5px;
  padding: 1rem;
  border-color: #f9f7f9;
}

div.product-icon img {
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 10px;
}

h2.product-name {
  color: #000000;
}

a.external-link {
  color: #000000;
  font-size: 1.5rem;
}

button.external-link {
  color: #000000;
  font-size: 1.5rem;
}

.left-button {
  margin-right: 1rem;
}

.right {
  float: right;
}

.text-right {
  text-align: right;
}

.left {
  float: left;
}

div.header-action-button {
  padding-right: 0;
  margin-top: -0.5rem;
}

div.page-header {
  float: left;
  margin-left: 1rem;
}

div.page-rule-container {
  float: right;
  width: 100%;
  padding-left: 0.5rem;
  margin-top: -0.25rem;
}

div.admin-button-container {
  margin-top: -1rem;
  float: left;
}

div.pro-badge-container {
  margin-top: -1.5rem;
  float: right;
}

span.pro-badge {
  background-color: #6086ee;
  color: #ffffff;
  font-size: 0.5rem;
  padding: 0.1rem;
  margin-bottom: 1rem;
}

/* toggle */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

/*icon colors*/

.green {
  color: #87d273;
}

.blue {
  color: #6086ee;
}

.coral {
  color: #ff7f50;
}

/*login screen*/

div.login-logo-container {
  margin-top: 4rem;
}

input.login {
  background-color: #f9f7f9;
  font-size: 16px;
}

/*products screen*/

div.products-container {
  margin-top: 1rem;
  margin-left: 0;
}

div.product {
  background-color: #f9f7f9;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
}

div.by-provider {
  font-size: 0.75rem;
  color: #8d8c8d;
}

div.product-detail-label {
  float: left;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding-top: 0.5rem;
  font-weight: 500;
  color: rgb(20, 43, 71);
}

div.product-detail-value {
  float: right;
  text-align: right;
}

.details-link {
  font-size: 0.75rem;
  margin-top: 1rem;
}

div.product button.admin-hover {
  display: none;
}

div.product:hover button.admin-hover {
  display: block;
}

div.left {
  float: left;
}

@media screen and (min-width: 760px) {
  div.products-container {
    margin-left: 2rem;
  }

  div.product {
    min-width: 250px;
  }
}

@media screen and (min-width: 1200px) {
  div.products-container {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 1200px) {
  div.product:hover button.admin-hover {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  div.product {
    margin: 1rem 3rem 1rem 2rem;
  }

  div.product:hover button.admin-hover {
    display: none;
  }
}

div.product-details-button-container {
  background-color: #4aace9;
  border-radius: 3px;
  width: 100%;
  margin-top: 1rem;
}

a.product-details-button {
  color: #ffffff;
  border-radius: 3px;
  border: none;
  padding: 0.25rem 2rem 0.25rem 2rem;
  display: inline-block;
  width: 100%;
  height: 100%;
}

/*product screen*/

div.my-products-container {
  margin-right: 0.5rem;
}

div.product-header {
  background-color: #f9f7f9;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
}

div.detail-product-icon {
  border-radius: 5px;
  margin-left: 1rem;
  border-color: #f9f7f9;
  float: left;
}

div.detail-product-icon img {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  width: 125px;
}

div.product-name-container {
  margin-top: 1.5rem;
}

div.product-description-container {
  margin-top: 1.5rem;
}

div.row.claims-container {
  margin-top: 0.5rem;
}

div.claims-shield-container {
  padding-right: 0;
}

div.claims-shield-container div {
  float: right;
}

div.claims-shield-container img {
  margin-right: 0.25rem;
  float: right;
}

pre.badge {
  background-color: #f9f7f9;
  border-radius: 5px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
}

div.evaluation-header {
  margin-left: 0;
  margin-top: 1rem;
  text-align: center;
}

div.evaluation-header div.col {
  text-align: center;
}

div.evaluation {
  background-color: #f9f7f9;
  border-radius: 3px;
  padding: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-left: 0;
}

div.evaluation button.admin-hover {
  display: none;
}

div.evaluation:hover button.admin-hover {
  display: inline;
  margin-left: -2rem;
}

div.evaluation div.score-container {
  margin-top: -0.3rem;
  visibility: hidden;
}

.display-none {
    display: none;
}

div.evaluation .badge-spacer {
  padding-top: 0.5rem;
}

div.evaluation .status-container {
  padding-top: 1.25rem;
}

@media screen and (max-width: 1200px) {
  div.product-name-container h2.product-name {
    font-size: 1.5rem;
  }

  div.evaluation-header {
    font-size: 0.75rem;
  }

  div.evaluation div.score-container {
    margin-top: -0.5rem;
  }

  div.evaluation:hover button.admin-hover {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  div.product-name-container h2.product-name {
    font-size: 1.5rem;
  }

  div.evaluation-header {
    font-size: 0.5rem;
  }

  div.evaluation {
    font-size: 0.25rem;
    padding: 0;
  }

  div.evaluation .status {
    font-size: 0.6rem;
  }

  div.evaluation div.score-container {
    margin-top: 0.1rem;
  }

  div.evaluation .score-number {
    font-size: 1.5rem;
  }

  div.evaluation .score-decimal {
    font-size: 1rem;
  }

  div.evaluation .external-link {
    font-size: 0.75rem;
  }

  div.evaluation .status-container {
    padding-top: 1rem;
  }

  div.evaluation:hover button.admin-hover {
    display: none;
  }
}

/*status component*/

span.status {
  color: #8d8c8d;
  font-size: 1.25rem;
  font-weight: 300;
}

span.status.date {
  color: #6dac4a;
}

span.status.active {
  text-transform: uppercase;
  color: #6dac4a;
}

span.status.expired {
  text-transform: uppercase;
  color: #af615f;
}

span.status.pending {
  text-transform: uppercase;
  color: #e49e3f;
}

/*users screen*/

div.user-header-container {
  float: left;
  margin-left: 1rem;
}

div.user-details {
  font-size: 0.75rem;
}

.email-rule {
  margin: 0.5rem 0;
}

/*score component*/

span.score-number {
  font-size: 3rem;
  color: #27538f;
  font-family: "Michroma";
}

span.score-decimal {
  font-size: 2rem;
  color: #27538f;
  font-family: "Michroma";
}

span.score-out-of {
  font-size: 0.75rem;
}

/*nav menu component*/

span.menu-icon {
  background-color: #6086ee;
  color: #ffffff;
  font-size: 0.5rem;
  padding: 0.1rem;
  margin-bottom: 1rem;
}

.nav-name-wrapper {
  text-align: right;
  margin-left: 1.5rem;
}

.logout {
  font-size: 0.75rem;
}

/*report*/
div.report-product-header-container {
  margin-right: 0.5rem;
}

div.report-product-header {
  padding: 1rem;
  margin: 0 1rem;
  background-color: #f9f7f9;
  border-radius: 5px;
}

div.report-product-icon {
  width: 125px;
  height: 125px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgb(161 161 161);
  margin-left: 1rem;
}

div.expand-collapse-all {
  margin-top: 2.5rem;
}

div.report-product-icon img {
  width: 125px;
}

div.report-section-header {
  color: #000000;
  font-size: 1.25rem;
  margin-top: 2rem;
  font-weight: 500;
}

div.report-section-header img {
  width: 40px;
  margin-right: -1rem;
  margin-top: -1.5rem;
}

.report-section-icon {
  color: rgb(124, 196, 237);
}

span.category-header {
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
}

.info-circle {
  color: rgb(124, 196, 237);
}

div.guardians-container {
  margin-bottom: 0.5rem;
}

div.category-description {
  margin: 0 1rem;
  background-color: #f9f7f9;
  border-radius: 5px;
  padding: 0.5rem;
}

.category-description-close-icon {
  color: rgb(161 161 161);
  font-size: 0.75rem;
  font-weight: 400;
  margin-right: 2rem;
  float: right;
}

div.guardians-container div.average-rating-container {
  margin-left: 0.25rem;
}

div.overall-score-container {
  text-align: center;
}

div.overall-container {
  margin: 0;
  font-size: 1.75rem;
  color: #000000;
}

@media screen and (max-width: 760px) {
    div.overall-container {
        font-size: 0.5rem;
    }
}

div.completed-container {
  font-size: 0.75rem;
}

div.completed-container span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
}

div.average-rating {
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75rem;
}

div.category-bar-chart-name {
  text-align: right;
  color: #000000;
  text-transform: uppercase;
  padding: 0.5rem 0.25rem;
  font-size: 0.75rem;
}

div.category-score-bar-container {
  border-left: 2px #000000 solid;
  padding: 0.25rem 0;
  color: transparent;
}

div.score-bar {
  color: transparent;
  float: left;
  background-color: rgb(9, 83, 144);
}

div.background-bar {
  background-color: rgb(201, 201, 201);
  width: 100%;
  color: transparent;
}

div.category-score-container {
  margin-top: -1rem;
  margin-left: -0.25rem;
}

div.ease-of-use-summary {
  margin: 0.25rem 0.25rem 0.5rem 0.25rem;
  background-color: #f9f7f9;
  border-radius: 5px;
  padding: 1rem;
}

span.ease-of-use-summary-header {
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
  font-size: 0.75rem;
}

div.ease-of-use-text {
  color: rgb(51, 51, 51);
}

div.ease-of-use-recommendations {
  margin: 0.25rem 0.25rem 0.5rem 0.25rem;
  background-color: #eaf4fe;
  border-radius: 5px;
  margin-top: 0.5rem;
  padding: 1rem;
}

div.subcategories-container {
  padding: 1.5rem;
}

div.subcategory-container {
  background-color: #f9f7f9;
  margin: 0.5rem 0.5rem;
  padding: 1rem;
  border-radius: 5px;
}

span.subcategory-name {
  color: #000000;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.75rem;
}

div.category-icon-container {
  margin-top: 0.5rem;
  color: rgb(124, 196, 237);
  font-size: 0.75rem;
}

hr.category-rule {
  margin: 0.25rem 0;
}

div.criteria-header span {
  color: rgb(51, 51, 51);
}

div.criteria-detail-row {
  background-color: #dcdcdc;
  font-size: 0.75rem;
  color: #000000;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.criteria-detail-close {
  color: rgb(82, 82, 82);
  font-size: 0.75rem;
  float: right;
  padding-top: 0.25rem;
}

.score-out-of-container {
  margin-bottom: 0;
  display: none;
}

div.ease-of-use-recommendations span {
  font-weight: 500;
  text-transform: "uppercase";
}

fieldset {
  background-color: white;
  border-radius: 25px;
  border-color: rgba(207, 207, 207, 0.39);
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
}

legend {
  color: rgb(72, 83, 224);
  text-transform: uppercase;
  margin-left: 15px;
  margin-bottom: 0;
  padding: 0.5rem;
  border: 0;
  font-size: 0.75rem;
  display: block;
  width: 18rem;
}

.barchart-0 {
  /* width: 0%; */
  background-color: transparent;
}

.barchart-1 {
  width: 20%;
  background-color: rgb(179, 216, 245);
}

.barchart-2 {
  width: 40%;
  background-color: rgb(108, 168, 216);
}

.barchart-3 {
  width: 60%;
  background-color: rgb(57, 133, 193);
}

.barchart-4 {
  width: 80%;
  background-color: rgb(35, 112, 174);
}

.barchart-5 {
  width: 100%;
  background-color: rgb(9, 83, 144);
}

.back-to-top-wrapper {
  transition: all 0.5s ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 1em 1em 0;
  border-radius: 50%;
  padding: 0.25em;
  width: 60px;
  height: 60px;
  background-color: #f8f8f8;
}

.back-to-top-wrapper:hover {
  background-color: rgb(108, 168, 216);
}

.back-to-top-wrapper span {
  color: blue;
}

div.claims-content-wrapper {
  padding-left: 0.25rem;
}

div.claims-header {
  color: #000000;
  font-weight: 400;
  font-size: 1.1rem;
}

div.claims-header:hover {
  cursor: pointer;
}

div.claims-subsection-header {
  font-weight: 500;
  font-size: 0.75rem;
}

div.claims-notes {
  margin: 0.25rem 0.25rem 0.5rem 0.25rem;
  background-color: #f9f7f9;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}

div.attachment-icon-container {
  color: rgb(184, 40, 29);
}

a.attachment-link {
  color: #919191;
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  div.report-product-header h2.product-name {
    font-size: 1.5rem;
  }
}

/*sidebar*/

.sidebar {
  padding: 0.5rem;
  border-radius: 10px;
  width: 2.5rem;
  position: fixed;
  z-index: 10;
  top: 100px;
  left: 20px;
  background-color: rgb(249, 247, 249);
  transition: 0.25s;
  overflow-x: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

.sidebar:hover {
  width: 30rem;
  border: 1px solid #000000;
}

.icon-text {
  margin-left: 2rem;
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .sidebar {
    display: none;
  }
}

/*Footer*/

.footer {
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  z-index: 15;
  background-color: #fff;
}

.mainDiv {
  padding-bottom: 35px;
}

.pointer {
  cursor: pointer;
}

.expandCollapse {
  width: 16px !important;
}

.centerText {
    text-align: center;
}